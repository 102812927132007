var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          staticStyle: { "text-aglin": "left" },
          attrs: {
            visible: _vm.visiable,
            title: "公告详情",
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": true,
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c("div", { staticClass: "box" }, [
            _vm._v(" " + _vm._s(_vm.content) + " "),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "en-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visiable = false
                    },
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }