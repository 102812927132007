<!--
 * @Author: zhangjiaheng
 * @Date: 2020-11-27 09:56:19
 * @LastEditTime: 2021-06-22 16:32:58
 * @LastEditors: Please set LastEditors
 * @Description: 消息中心带按钮操作的弹框
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewBtnOperation.vue
-->
<template>
  <div>
    <en-dialog :visible="visiable" title="" :append-to-body="true" :close-on-click-modal="false" @close="closeDialog" style="text-aglin:left;" width="500px">
      <div class="box">
        <div class="box-left" v-if="title!=='新增'">
          <en-icon name="tishi" size="34" :style="color"></en-icon>
        </div>
        <div class="box-right">
          <p class="box-right-p1">{{ title }}</p>
          <p class="box-right-p2">{{ content.message }}</p>
        </div>
      </div>
      <div slot="footer">
        <en-button v-for="(item, key) in btnCustom" :key="key" :type="item.type" @click="btnClick(item)">
          {{ item.name }}
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { messageNewButtonEntranceRoute } from "@/components/businessDetail/helper.js";
import { enService } from "@/api/en/index";

export default {
  name: "viewBtnOperation",
  props: {
    // 该条信息的 具体数据
    content: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    read: {}
  },
  data() {
    return {
      visiable: false,
      btnCustom: [{ type: "white", name: "查看详情", id: "viewDetails" }],
      title: "",
      color: "color: #3e90fe;"
    };
  },
  mounted() {
    (this.content.buttonKey || []).forEach((element) => {
      if (element.buttonKey === "add") {
        this.title = "新增";
        this.color = "color: #3e90fe;";
        this.btnCustom.push({
          id: "add", type: "primary", name: element.name, element
        });
      }
      if (element.buttonKey === "entry") {
        this.title = "参与提醒";
        this.color = "color: #3e90fe;";
        this.btnCustom.push({
          id: "entry", type: "primary", name: element.name, element
        });
      }
      if (element.buttonKey === "delete") {
        this.title = "删除提醒";
        this.color = "color: #f76b6b;";
        this.btnCustom.push({
          id: "delete", type: "danger", name: element.name, element
        });
      }
    });
    this.visiable = this.show;
  },
  methods: {
    async btnClick(item) {
      console.log(item);
      if (item.id === "viewDetails") {
        // 进入详情查看
        this.$emit("viewDetails");
      } else if (item.id === "add") {
        // 跳转到业务建模新增数据
        this.$router.push(
          messageNewButtonEntranceRoute({
            templateId: item.element.refId,
            businessType: item.element.refType,
            templateName: "",
            operateId: item.element.id,
            srcDataId: this.content.businessKey,
            srcTemplateId: this.content.messageId,
            srcType: this.content.type,
            destTemplateId: item.element.refId,
            destType: item.element.operateType
          })
        );
        // path, templateId, businessType = 2, templateName, operateId, srcDataId, srcTemplateId, destTemplateId, destType, srcType
        // obj = item.element  item = this.content
        // "&ref_opId=" + add.id + "&ref_srcdId=" + item.businessKey + "&ref_srctId=" + item.messageId + "&ref_destId=" + add.refId + "&ref_dtType=" + add.operateType + "&ref_srcType=" + item.type;
        // url = "/toModelUserComm.do?templateId=" + (obj.refId||"") + "&rsid=&flg=" + flg + "&type=" + obj.refType + para + "&bUrl=" + utils.base64Encode(window.location.href);
      } else if (item.id === "entry") {
        // 接口处理 加入主题
        // {id: item.businessKey,templateId:obj.refId}
        await enService.ptJoin({ id: this.content.businessKey, templateId: item.element.refId });
        this.$message({
          showClose: true,
          message: "加入主题成功",
          type: "warning"
        });
      } else if (item.id === "delete") {
        // 接口处理 主题删除
        await enService.ptOut({ id: this.content.businessKey, templateId: item.element.refId });
        this.$message({
          showClose: true,
          message: "删除主题成功",
          type: "warning"
        });
      }
      this.visiable = false;
    },
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 10px auto 0;
  width: 100%;
  min-height: 80px;
  // background: rgba(245, 248, 252, 1);
  // border: 1px solid rgba(232, 236, 242, 1);
  border-radius: 5px;
  padding: 19px 18px 30px 18px;
  // font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  display: flex;
  .box-left {
    width: 45px;
  }
  .box-right {
    margin: 0;
    p {
      margin: 0;
    }
    .box-right-p1 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 19px;
    }
    .box-right-p2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #636c78;
      line-height: 22px;
    }
  }
}
</style>
