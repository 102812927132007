var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-enlis",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hideSearch.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "ybox-mod enbuss-mod" },
        [
          _c("card-header", {
            staticClass: "ybox-title",
            attrs: { title: _vm.headerTitle, edit: _vm.$attrs.edit },
            on: { deleteData: _vm.deleteData },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "pull-left" },
                      [
                        _vm.flag
                          ? _c("en-icon", {
                              staticStyle: {
                                "vertical-align": "middle",
                                cursor: "pointer",
                                "margin-left": "0px",
                              },
                              attrs: { name: "fanhui", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.callback.apply(null, arguments)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(_vm.headerTitle))]),
                        _vm.num
                          ? _c("span", { staticClass: "num" }, [
                              _vm._v(_vm._s(_vm.num)),
                            ])
                          : _vm._e(),
                        _vm.type === "itself" && !_vm.$attrs.edit
                          ? _c("en-select", {
                              staticStyle: {
                                display: "inline-block",
                                width: "140px",
                                "margin-left": "5px",
                              },
                              attrs: {
                                placeholder: "请选择",
                                data: _vm.options,
                                width: 140,
                                filterable: "",
                              },
                              on: { change: _vm.change },
                              model: {
                                value: _vm.readFlag,
                                callback: function ($$v) {
                                  _vm.readFlag = $$v
                                },
                                expression: "readFlag",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "pull-right" },
                      [
                        _vm.type === "itself" && !_vm.$attrs.edit
                          ? _c("viewSearch", {
                              attrs: { show: _vm.show },
                              on: { toData: _vm.getData },
                            })
                          : _vm._e(),
                        _vm.type === "itself" && !_vm.$attrs.edit
                          ? _c("span", { staticClass: "shu" })
                          : _vm._e(),
                        (_vm.type === "itself" || _vm.mainBatchOpera) &&
                        !_vm.$attrs.edit
                          ? _c(
                              "en-image-setting",
                              {
                                staticClass: "piliangchuli-weidu",
                                attrs: {
                                  name: "piliangchuli-weidu",
                                  size: "small",
                                  fill: "#62b59c",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.read()
                                  },
                                },
                              },
                              [_vm._v(" 一键阅读 ")]
                            )
                          : _vm._e(),
                        _vm.type === "index" && !_vm.$attrs.edit
                          ? _c("en-icon", {
                              staticStyle: {
                                color: "#A9B5C6",
                                cursor: "pointer",
                                "margin-left": "10px",
                              },
                              attrs: {
                                name: "zuocecaidanlan-shouqi",
                                size: "20",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.goDetails("/myRead")
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ybox-content",
            },
            [
              !_vm.noData
                ? _c(
                    "div",
                    {
                      staticClass: "enlis enlis-buss",
                      attrs: { id: "myread-enlis" },
                    },
                    _vm._l(_vm.noticeList, function (item, k) {
                      return _c("div", { key: k, staticClass: "mod-box" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.shadow,
                                expression: "item.shadow",
                              },
                            ],
                            staticClass: "operate-lis",
                          },
                          [
                            _c("div", { staticClass: "cent" }, [
                              _c("div", { staticClass: "o-mask" }),
                              _c(
                                "div",
                                { staticClass: "o-lis" },
                                [
                                  _c(
                                    "en-image-setting",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        "margin-right": "10px",
                                      },
                                      attrs: {
                                        name: "yidu-liebiao",
                                        size: "33",
                                        fill: "#F5FBFF",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.read(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 已读 ")]
                                  ),
                                  _c(
                                    "en-image-setting",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        "margin-right": "10px",
                                      },
                                      attrs: {
                                        name: "tixinganniu-kuaijiecaozuo",
                                        size: "33",
                                        fill: "#F5FBFF",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.remind(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 稍后提醒 ")]
                                  ),
                                  _c(
                                    "en-image-setting",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        "margin-right": "10px",
                                      },
                                      attrs: {
                                        name: "shibai",
                                        size: "33",
                                        fill: "#F5FBFF",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteNotice(item, k)
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mod",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.modClick(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col col-icon",
                                class:
                                  item.readFlag === "000" ? "msg-remind" : "",
                              },
                              [
                                _c("p", [
                                  item.type == "099"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "iconfont",
                                          staticStyle: {
                                            background: "#4ed3c3",
                                          },
                                        },
                                        [
                                          _c("en-icon", {
                                            staticStyle: { color: "#FFF" },
                                            attrs: {
                                              name:
                                                _vm.iconMap[item.type].name ||
                                                "xiaoxi",
                                              size: "18",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "iconfont" },
                                        [
                                          _c("en-icon", {
                                            attrs: {
                                              name:
                                                _vm.iconMap[item.type] ||
                                                "xiaoxi",
                                              size: "18",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "pull-left" }, [
                              _c("div", { staticClass: "col col-num" }, [
                                _c("p", [_vm._v(_vm._s(item.name))]),
                              ]),
                              _c("div", { staticClass: "col col-tit" }, [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.message(item)),
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "pull-right" }, [
                              _c("div", { staticClass: "col col-time" }, [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDateFormat(item["createTime"])
                                    )
                                  ),
                                ]),
                              ]),
                              item.type !== "099"
                                ? _c(
                                    "div",
                                    { staticClass: "col col-operate" },
                                    [
                                      item.readFlag === "000"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "span-btn",
                                              attrs: { title: "已读" },
                                            },
                                            [
                                              _c("en-icon", {
                                                staticStyle: {
                                                  color: "#3e90fe",
                                                },
                                                attrs: {
                                                  name: "yidu-liebiao",
                                                  size: "20",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.read(item)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.readFlag === "000"
                                        ? _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "bottom",
                                                width: "200",
                                                trigger: "hover",
                                                "popper-class": "myRead__more",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "o-lis" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "lis-btn" },
                                                    [
                                                      _c("en-icon", {
                                                        staticStyle: {
                                                          color: "#3e90fe",
                                                        },
                                                        attrs: {
                                                          name: "yiduanniu-kuaijiecaozuo",
                                                          size: "20",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.read(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "o-lis-tit",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.read(item)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("已读")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "lis-btn" },
                                                    [
                                                      _c("en-icon", {
                                                        staticStyle: {
                                                          color: "#fd9a4a",
                                                        },
                                                        attrs: {
                                                          name: "tixinganniu-kuaijiecaozuo",
                                                          size: "20",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.remind(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "o-lis-tit",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.remind(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("稍后提醒")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "lis-btn" },
                                                    [
                                                      _c("en-icon", {
                                                        staticStyle: {
                                                          color: "#f54646",
                                                        },
                                                        attrs: {
                                                          name: "iconshaixuantiaojianshanchu21",
                                                          size: "20",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.deleteNotice(
                                                              item,
                                                              k
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "o-lis-tit",
                                                      staticStyle: {
                                                        "margin-right": "15px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteNotice(
                                                            item,
                                                            k
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "span-btn",
                                                  staticStyle: {
                                                    "margin-left": "16px",
                                                  },
                                                  attrs: {
                                                    slot: "reference",
                                                    title: "更多",
                                                  },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("en-icon", {
                                                    staticStyle: {
                                                      color: "#25C393",
                                                    },
                                                    attrs: {
                                                      name: "gengduo-liebiao",
                                                      size: "20",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.readFlag === "001"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "span-btn",
                                              attrs: { title: "未读" },
                                            },
                                            [
                                              _c("en-icon", {
                                                staticStyle: {
                                                  color: "#3e90fe",
                                                },
                                                attrs: {
                                                  name: "weidu-liebiao",
                                                  size: "20",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.unread(item)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.readFlag === "001"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "span-btn",
                                              staticStyle: {
                                                "margin-left": "16px",
                                              },
                                              attrs: { title: "删除" },
                                            },
                                            [
                                              _c("en-icon", {
                                                staticStyle: {
                                                  color: "#f54646",
                                                },
                                                attrs: {
                                                  name: "shibai",
                                                  size: "20",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.deleteNotice(
                                                      item,
                                                      k
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.noData
                ? _c("en-result", { attrs: { type: "NoData" } })
                : _vm._e(),
              _vm.myNotice.hasNext
                ? _c("viewAddMore", {
                    attrs: {
                      total: _vm.myNotice.total,
                      pageNo: _vm.pageNo,
                      pageSize: _vm.pageSize,
                    },
                    on: {
                      addMore: function ($event) {
                        return _vm.addMore("/myRead")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.noticeType.type019.flg
        ? _c("viewNotice", {
            attrs: {
              show: _vm.noticeType.type019.flg,
              content: _vm.noticeType.type019.content,
            },
            on: {
              toShow: function ($event) {
                return _vm.toget("type019")
              },
            },
          })
        : _vm._e(),
      _vm.noticeType.type048.flg
        ? _c("viewExamination", {
            attrs: {
              show: _vm.noticeType.type048.flg,
              content: _vm.noticeType.type048.content,
            },
            on: {
              toShow: function ($event) {
                return _vm.toget("type048")
              },
            },
          })
        : _vm._e(),
      _vm.noticeType.remind.flg
        ? _c("viewRemind", {
            attrs: {
              show: _vm.noticeType.remind.flg,
              content: _vm.noticeType.remind.content,
            },
            on: {
              toShow: function ($event) {
                return _vm.toget("remind")
              },
              success: _vm.refeshData,
            },
          })
        : _vm._e(),
      _vm.noticeType.btnOperation.flg
        ? _c("viewBtnOperation", {
            attrs: {
              show: _vm.noticeType.btnOperation.flg,
              content: _vm.noticeType.btnOperation.content,
            },
            on: {
              toShow: function ($event) {
                return _vm.toget("btnOperation")
              },
              viewDetails: function ($event) {
                return _vm.messageOperation(_vm.noticeType.btnOperation.content)
              },
            },
          })
        : _vm._e(),
      _vm.noticeType.nodeDetailShow.flg
        ? _c("nodeDetail", {
            attrs: {
              "dialog-visible": _vm.noticeType.nodeDetailShow.flg,
              "all-node-list": [_vm.currentNode],
              "current-node": _vm.currentNode,
            },
            on: {
              "update:dialogVisible": function ($event) {
                return _vm.$set(_vm.noticeType.nodeDetailShow, "flg", $event)
              },
              "update:dialog-visible": function ($event) {
                return _vm.$set(_vm.noticeType.nodeDetailShow, "flg", $event)
              },
            },
          })
        : _vm._e(),
      _c("viewBacktop", {
        staticClass: "back-top",
        attrs: { elementId: "myread-enlis" },
      }),
      _vm.noticeType.type035.flg
        ? _c("prompt", {
            attrs: {
              "show-prompt": _vm.noticeType.type035.flg,
              payRollId: _vm.noticeType.type035.content,
            },
            on: {
              closePrompt: function ($event) {
                _vm.type035.flg = false
              },
              promptCallBack: _vm.payRollVerfyCallBack,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }