<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-30 15:41:47
 * @LastEditTime: 2020-11-27 14:41:31
 * @LastEditors: Please set LastEditors
 * @Description: 消息中心017类型的公告数据
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewNotice.vue
-->
<template>
  <div>
    <en-dialog
      :visible="visiable"
      title="公告详情"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="800px"
      style="text-aglin:left;"
      :append-to-body="true"
    >
      <div class="box">
        {{content}}
      </div>
      <div slot="footer">
        <en-button @click="visiable=false">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
export default {
  name: "viewNotice",
  props: {
    // 公共数据
    content: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false
    };
  },
  mounted() {
    this.visiable = this.show;
  },
  methods: {
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    }
  }

};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  width: 100%;
  min-height: 115px;
  background: rgba(245, 248, 252, 1);
  border: 1px solid rgba(232, 236, 242, 1);
  border-radius: 5px;
  padding: 19px 18px 30px 18px;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
}
</style>
