<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-30 15:41:47
 * @LastEditTime: 2021-11-26 15:14:44
 * @LastEditors: Please set LastEditors
 * @Description: 消息中心稍后提醒
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewRemind.vue
-->
<template>
  <div>
    <en-dialog
      :visible="visiable"
      title="提醒"
      :close-on-click-modal="false"
      @close="closeDialog"
      style="text-aglin:left;"
      :append-to-body="true"
      width="800px"
    >
      <div class="box">
        <div class="mod">
          <span class="tit">选择时间</span>
          <el-radio v-model="lateTime" label="30">30分钟后</el-radio>
          <el-radio v-model="lateTime" label="60">1小时后</el-radio>
          <el-radio v-model="lateTime" label="120">2小时后</el-radio>
          <el-radio v-model="lateTime" label="180">3小时后</el-radio>
          <el-radio v-model="lateTime" label="240">4小时后</el-radio>
        </div>
        <div class="mod">
          <span class="tit visibility">选择时间</span>
          <el-radio v-model="lateTime" label="custom">自定义</el-radio>
          <en-date-picker
            v-if=" lateTime === 'custom' "
            v-model="sendTime"
            type="datetime"
            style="width:366px;height:32px;"
            :editable="false"
            size="small"
            placeholder="请选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </en-date-picker>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="imAddMsgNotice">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { enService } from "@/api/en/index";

export default {
  name: "viewRemind",
  props: {
    // 消息中心整个数据体
    content: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      lateTime: "",
      sendTime: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7;// 如果没有后面的-8.64e7就是不可以选择今天的
        }
      }
    };
  },
  mounted() {
    this.visiable = this.show;
  },
  methods: {
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    },
    async imAddMsgNotice() {
      console.log(this.lateTime);
      const param = { id: this.content.id, type: "1" };
      if (!this.lateTime) {
        this.$message({
          message: "请选择提醒时间",
          type: "warning"
        });
        return;
      } if (this.lateTime !== "custom") {
        param.lateTime = this.lateTime;
      } else {
        console.log(this.sendTime);
        if (!this.sendTime) {
          this.$message({
            message: "请选择自定义提醒时间",
            type: "warning"
          });
          return;
        }
        if (new Date(this.sendTime).getTime() < new Date().getTime()) {
          this.$message({
            message: "请选择正确的自定义提醒时间",
            type: "warning"
          });
          return;
        }
        param.sendTime = this.sendTime;
      }
      await enService.imAddMsgNotice(param);
      this.$emit("success");
      this.$message({
        message: "设置稍后提醒成功",
        type: "success"
      });
      this.visiable = false;
      this.closeDialog();
    }
  }

};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  max-width: 800px;
  min-height: 200px;
  // background:rgba(245,248,252,1);
  // border:1px solid rgba(232,236,242,1);
  // border-radius:5px;
  // padding: 19px 18px 30px 18px;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  .mod {
    height: 32px;
    width: 100%;
    line-height: 32px;
    margin-bottom: 10px;
    .tit {
      margin-right: 15px;
    }
    .visibility {
      visibility: hidden;
    }
  }
}
</style>
