var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          staticStyle: { "text-aglin": "left" },
          attrs: {
            visible: _vm.visiable,
            title: "",
            "append-to-body": true,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c("div", { staticClass: "box" }, [
            _vm.title !== "新增"
              ? _c(
                  "div",
                  { staticClass: "box-left" },
                  [
                    _c("en-icon", {
                      style: _vm.color,
                      attrs: { name: "tishi", size: "34" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "box-right" }, [
              _c("p", { staticClass: "box-right-p1" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("p", { staticClass: "box-right-p2" }, [
                _vm._v(_vm._s(_vm.content.message)),
              ]),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            _vm._l(_vm.btnCustom, function (item, key) {
              return _c(
                "en-button",
                {
                  key: key,
                  attrs: { type: item.type },
                  on: {
                    click: function ($event) {
                      return _vm.btnClick(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }