<template>
  <!--消息中心-->
  <div class="my-enlis" @click.stop="hideSearch">
    <div class="ybox-mod enbuss-mod">
      <card-header :title="headerTitle" :edit='$attrs.edit' @deleteData="deleteData" class='ybox-title'>
        <template #left  >
          <div class="pull-left">
            <en-icon v-if="flag" name="fanhui" size="small" style="vertical-align:middle;cursor: pointer;margin-left: 0px;" @click.native="callback"> </en-icon>
            <span>{{ headerTitle }}</span>
            <span v-if="num" class="num">{{ num }}</span  >
            <en-select
              v-model="readFlag"
              v-if="type === 'itself'&&!$attrs.edit"
              placeholder="请选择"
              :data="options"
              :width="140"
              filterable style="display: inline-block;width: 140px;margin-left:5px;" @change="change"></en-select>
        </div>
        </template>
        <template #right >
          <div class="pull-right">
            <viewSearch v-if="type === 'itself'&&!$attrs.edit" :show="show" @toData="getData"></viewSearch>
            <span v-if="(type === 'itself')&&!$attrs.edit" class="shu"></span>
            <en-image-setting v-if="(type === 'itself' || mainBatchOpera)&&!$attrs.edit" class="piliangchuli-weidu" name="piliangchuli-weidu" size="small" fill="#62b59c" @click.native="read()">
              一键阅读
            </en-image-setting>
            <en-icon
              v-if="type === 'index'&&!$attrs.edit"
              name="zuocecaidanlan-shouqi"
              size="20"
              style="color: #A9B5C6;cursor: pointer;margin-left: 10px;"
              @click.native="goDetails('/myRead')"></en-icon>
        </div>
        </template>
      </card-header>
      <div class="ybox-content" v-en-loading="loading">
        <div class="enlis enlis-buss" v-if="!noData" id="myread-enlis">
          <div v-for="(item, k) in noticeList" :key="k" class="mod-box">
            <div class="operate-lis" v-show="item.shadow">
              <div class="cent">
                <div class="o-mask"></div>
                <div class="o-lis">
                  <en-image-setting name="yidu-liebiao" size="33" fill="#F5FBFF" style="margin-left:10px;margin-right:10px;" @click.native.stop="read(item)">
                    已读
                  </en-image-setting>
                  <en-image-setting name="tixinganniu-kuaijiecaozuo" size="33" fill="#F5FBFF" style="margin-left:10px;margin-right:10px;" @click.native.stop="remind(item)">
                    稍后提醒
                  </en-image-setting>
                  <en-image-setting name="shibai" size="33" fill="#F5FBFF" style="margin-left:10px;margin-right:10px;" @click.native.stop="deleteNotice(item, k)">
                    删除
                  </en-image-setting>
                </div>
              </div>
            </div>
            <div class="mod" @click.stop="modClick(item)">
              <div class="col col-icon" :class="item.readFlag === '000' ? 'msg-remind' : ''">
                <p>
                  <span v-if="item.type == '099'" class="iconfont" style="background:#4ed3c3;">
                    <en-icon  :name="iconMap[item.type].name || 'xiaoxi'" style="color: #FFF;" size="18"></en-icon>
                  </span>
                  <span v-else class="iconfont">
                    <en-icon :name="iconMap[item.type] || 'xiaoxi'" size="18"></en-icon>
                  </span>
                </p>
              </div>
              <div class="pull-left">
                <div class="col col-num">
                  <p>{{ item.name }}</p>
                </div>
                <div class="col col-tit">
                  <p v-html="message(item)"></p>
                </div>
              </div>
              <div class="pull-right">
                <div class="col col-time">
                  <p>{{ getDateFormat(item["createTime"]) }}</p>
                </div>
                <div v-if="item.type !== '099'" class="col col-operate">
                  <span v-if="item.readFlag === '000'" class="span-btn" title="已读">
                    <en-icon name="yidu-liebiao" size="20" style="color:#3e90fe;" @click.native.stop="read(item)"></en-icon>
                  </span>
                  <!-- <span class="span-btn" slot="reference" title="更多">
                    <en-icon
                      v-if="item.readFlag === '000'"
                      name="gengduo-liebiao"
                      size="20"
                      style="color:#25C393;margin-left:16px;"
                      @click.native.stop="moreOperate(item)"
                    ></en-icon>
                  </span> -->
                  <el-popover placement="bottom" v-if="item.readFlag === '000'" width="200" trigger="hover" popper-class="myRead__more">
                    <div class="o-lis">
                      <span class="lis-btn">
                        <en-icon name="yiduanniu-kuaijiecaozuo" size="20" style="color:#3e90fe;" @click.native.stop="read(item)"></en-icon>
                      </span>
                      <span class="o-lis-tit" @click.stop="read(item)">已读</span>
                      <span class="lis-btn">
                        <en-icon name="tixinganniu-kuaijiecaozuo" size="20" style="color:#fd9a4a;" @click.native.stop="remind(item)"></en-icon>
                      </span>
                      <span class="o-lis-tit" @click.stop="remind(item)">稍后提醒</span>
                      <span class="lis-btn">
                        <en-icon name="iconshaixuantiaojianshanchu21" size="20" style="color:#f54646;" @click.native.stop="deleteNotice(item, k)"></en-icon>
                      </span>
                      <span class="o-lis-tit" style="margin-right:15px;" @click.stop="deleteNotice(item, k)">删除</span>
                    </div>
                    <span  class="span-btn" style="margin-left:16px;" slot="reference" title="更多">
                      <en-icon name="gengduo-liebiao" size="20" style="color:#25C393;"></en-icon>
                    </span>
                  </el-popover>
                  <span v-if="item.readFlag === '001'" class="span-btn" title="未读">
                    <en-icon name="weidu-liebiao" size="20" style="color:#3e90fe;" @click.native.stop="unread(item)"></en-icon>
                  </span>
                  <span v-if="item.readFlag === '001'" class="span-btn" style="margin-left:16px;" title="删除">
                    <en-icon name="shibai" size="20" style="color:#f54646;" @click.native.stop="deleteNotice(item, k)"></en-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <en-result v-if="noData" type="NoData"></en-result>
        <!-- 加载更多 -->
        <viewAddMore v-if="myNotice.hasNext" :total="myNotice.total" :pageNo="pageNo" :pageSize="pageSize" @addMore="addMore('/myRead')"></viewAddMore>
      </div>
    </div>
    <!-- 019 公告 -->
    <viewNotice v-if="noticeType.type019.flg" :show="noticeType.type019.flg" :content="noticeType.type019.content" @toShow="toget('type019')"></viewNotice>
    <!-- 048体检 -->
    <viewExamination v-if="noticeType.type048.flg" :show="noticeType.type048.flg" :content="noticeType.type048.content" @toShow="toget('type048')"></viewExamination>
    <!-- 消息稍后提醒 -->
    <viewRemind v-if="noticeType.remind.flg" :show="noticeType.remind.flg" :content="noticeType.remind.content" @toShow="toget('remind')" @success='refeshData'></viewRemind>
    <!-- 带按钮的操作弹框 -->
    <viewBtnOperation
      v-if="noticeType.btnOperation.flg"
      :show="noticeType.btnOperation.flg"
      :content="noticeType.btnOperation.content"
      @toShow="toget('btnOperation')"
      @viewDetails="messageOperation(noticeType.btnOperation.content)"
    ></viewBtnOperation>
    <!-- 行程单节点详情 -->
    <nodeDetail v-if="noticeType.nodeDetailShow.flg" :dialog-visible.sync="noticeType.nodeDetailShow.flg" :all-node-list="[currentNode]" :current-node="currentNode"> </nodeDetail>
    <viewBacktop class="back-top" elementId="myread-enlis"></viewBacktop>
    <prompt
      v-if="noticeType.type035.flg"
      :show-prompt="noticeType.type035.flg"
      :payRollId="noticeType.type035.content"
      @closePrompt="type035.flg = false"
      @promptCallBack="payRollVerfyCallBack"
    ></prompt>
  </div>
</template>

<script>
import { enService } from "@/api/en/index";
import en from "@/mixins/en/en"; // 公共方法
import Prompt from "@/components/paylogin-box/src/prompt/prompt";
import CardHeader from "@/views/home/components/card-header";
import viewNotice from "./readComm/viewNotice";
import viewExamination from "./readComm/viewExamination";
import viewRemind from "./readComm/viewRemind";
import viewBacktop from "./readComm/viewBacktop";
import viewSearch from "./readComm/viewSearch";
import viewAddMore from "./readComm/viewAddMore";
import viewBtnOperation from "./readComm/viewBtnOperation";
import nodeDetail from "../../../businessTravel/trip/nodeDetail";

export default {
  name: "MyRead",
  components: {
    viewNotice,
    CardHeader,
    viewExamination,
    viewRemind,
    viewBacktop,
    viewSearch,
    viewAddMore,
    viewBtnOperation,
    nodeDetail,
    Prompt
  },
  mixins: [en],
  computed: {
    headerTitle() {
      return "消息中心";
    },
    num() {
      return `${ this.myNotice.total && this.myNotice.total > 0 ? `${ this.myNotice.total }` : "" }`;
    }
  },
  data() {
    return {
      myNotice: {},
      noticeList: [], // 展示的 数据
      pageNo: 1, // 页码
      pageSize: 8, // 每页的 数量
      //   切换 用的
      readFlag: "000", // 查询标识
      options: [
        { value: "000", label: "未读" },
        { value: "001", label: "已读" },
        { value: "011", label: "全部" }
      ],
      // 是否显示无数据
      noData: false,
      // 标记是否显示 消息的详情标识
      noticeType: {
        type019: { flg: false, content: "" },
        type048: { flg: false, content: {} },
        remind: { flg: false, content: {} },
        btnOperation: { flg: false, content: {} },
        nodeDetailShow: { flg: false, content: {} },
        type035: { flg: false, content: "" }
      },
      // 查询条件 加载更多时用到
      params: {},
      show: true,
      iconMap: {
        "099": { name: "xiaoxi-huizhi", color: "#4ed3c3" }
      },
      currentNode: {},
      configValue: { "011": "2", "000": "1", "001": "3" },
      menuList: this.$store.getters["main/menuList"]
    };
  },
  mounted() {
    // console.log("ads,", this.$store.getters);
    // console.log(this.menuList);
    this.handle();
    // 先 查询 用户习惯  再查数据
    this.queryUserConfig();
  },
  methods: {
    // 查询用户习惯是 全部 还是 未读
    async queryUserConfig() {
      const p = { configKey: "keyNoticeConfig" };
      const data = (await enService.queryUserConfig(p)) || {};
      // console.log(data);
      if (data.value === "1") {
        // 未读消息
        this.readFlag = "000";
      } else if (data.value === "2") {
        this.readFlag = "011";
      } else if (data.value === "3") {
        this.readFlag = "001";
      } else {
        // 默认
        // 未读消息
        this.readFlag = "000";
      }
      this.getList();
    },
    // 下拉 切换
    async change() {
      this.pageNo = 1;
      this.handle();
      this.noticeList = [];
      // 切换搜索 清空
      //  保存操作 习惯 再条用查询接口
      await this.saveUserConfig();
      this.getList();
    },
    // 更新已加载数据
    refeshData() {
      const odlPageSize = this.pageSize;
      this.pageSize *= this.pageNo;
      this.pageNo = 1;
      this.getList();
      this.pageSize = odlPageSize;
    },
    async saveUserConfig() {
      const params = {
        configKey: "keyNoticeConfig",
        configValue: this.configValue[this.readFlag]
      };
      enService.saveUserConfig(params);
    },
    // 查询数据
    async getList(flg) {
      if (flg) {
        // flg为true为加载更多不loading
      } else {
        this.loading = true;
      }
      let p = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        readFlag: this.readFlag === "011" ? "" : this.readFlag
      };
      if (JSON.stringify(this.params) !== "{}") {
        p = Object.assign(p, this.params);
      }
      if (this.pageNo === 1) {
        this.noticeList = [];
      }
      let rspData;
      try {
        rspData = await enService.Notice(p);
      } catch (error) {
        rspData = { records: [] };
      }
      this.myNotice = rspData;
      rspData.records.forEach((element) => {
        this.$set(element, "shadow", false);
      });
      this.noticeList.push(...rspData.records);
      // 判断是否无数据 避免视图闪现无数据
      if (this.noticeList.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.loading = false;
    },
    // 处理 内容显示的文字处理
    message(item) {
      if (item.type === "041") {
        // 文档的特殊处理
        const arr = item.message.split(item.formName);
        const htmlstr = `${arr[0]}<span style="color:#49bef3">${item.formName}</span>${arr[1]}`;
        return htmlstr;
      }
      return item.message || "";
    },
    deleteData() {
      this.$emit("deleteData");
    },
    // 点击处理
    async modClick(item) {
      console.log("输出点击项", item);
      // 未读变为已读
      if (item.readFlag === "000" && item.type !== "099") await this.read(item);
      // 操作处理
      let length = 0;
      try {
        length = item.buttonKey.length;
      } catch (error) {
        length = 0;
      }
      if (length > 0) {
        // 有操作按钮
        this.noticeType.btnOperation.content = item;
        this.noticeType.btnOperation.flg = true;
      } else {
        this.messageOperation(item);
      }
    },
    // 阅读item 无item标识一键阅读
    async read(item) {
      if (item) {
        // 单个操作
        const params = {
          ids: item.id,
          isBatch: "1"
        };
        const rspData = await enService.readNotice(params);
        if (rspData.successNum === 1) {
          item.readFlag = "001";
        }
        this.pageNo = 1;
        this.handle();
        this.noticeList = [];
        this.getList();
      } else {
        // 批量操作
        const ids = [];
        this.noticeList.forEach((element) => {
          if (element.readFlag === "000" && element.type !== "099") {
            ids.push(element.id);
          }
        });
        if (ids.length === 0) {
          this.$message({
            showClose: true,
            message: "没有可处理的未读通知",
            type: "warning"
          });
        } else {
          const params = {
            ids: ids.join(","),
            isBatch: "0"
          };
          const rspData = await enService.readNotice(params);
          // {failNum: 1, noBatchNum: 0, successNum: 10}
          const arr = [];
          if (Number(rspData.successNum) > 0) {
            arr.push(`成功${rspData.successNum}条`);
          }
          if (Number(rspData.failNum) > 0) {
            arr.push(`失败${rspData.failNum}条`);
          }
          if (Number(rspData.noBatchNum) > 0) {
            arr.push(`不能处理${rspData.noBatchNum}条`);
          }
          const str = `批量处理：${arr.join(",")}!`;
          this.$message({
            showClose: true,
            message: str,
            type: "success"
          });
          this.pageNo = 1;
          this.handle();
          this.noticeList = [];
          this.getList();
        }
      }
    },
    // 未读
    async unread(item) {
      const params = {
        id: item.id
      };
      await enService.convertToBeRead(params);
      item.readFlag = "000";
    },
    // 删除
    async deleteNotice(item) {
      const params = {
        id: item.id
      };
      await enService.deleteNotice(params);
      this.refeshData();
      this.$message({
        message: "操作成功！",
        type: "success"
      });
    },
    // 更多
    moreOperate(item) {
      item.shadow = !item.shadow;
    },
    // 稍后提醒
    remind(item) {
      this.noticeType.remind.flg = true;
      this.noticeType.remind.content = item;
    },
    // 弹框返回事件
    toget(item) {
      this.noticeType[item].flg = false;
    },
    // 获取搜索数据
    getData(data) {
      // 混入方法
      this.search(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
// 一键阅读
.piliangchuli-weidu {
  color: #bebebe;
  line-height: 35px;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
}

// 红点
.msg-remind::before {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: #ff6432;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 0;
  //   right: 10px;
  right: 0;
}

// 最外层
.my-enlis {
  position: relative;
  width: 100%;
  height: 100%;
  .ybox-mod {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 100%;
    .card-define-head{
      padding:5px 20px;
      // /deep/.right{
      //   min-width: 858px !important;
      // }
    }
    // 标题
    .ybox-title {
      color: #333;
      font-size: 14px;
      line-height: 22px;
      zoom: 1;
      height: auto;
      // border-bottom: 1px solid #ecf1f7;
      padding: 5px 15px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .pull-left {
        // float: left !important;
        font-size: 14px;
        height: 40px;
        // font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
        font-weight: bold;
        // min-width: 261px;
        display: flex;
        align-items: center;
        .num {
          padding: 3px 6px;
          background: #F54646;
          border-radius: 16px;
          font-size: 10px;
          min-width: 26px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          color: #FFFFFF;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
      }

      .pull-right {
        // float: right !important;
        height: 40px;
        display: flex;
        align-items: center;
        .shu {
          display: inline-block;
          width: 1px;
          height: 24px;
          background: #dbe5eb;
          vertical-align: middle;
          margin: 0 10px;
        }
      }
    }

    // 内容
    .ybox-content {
      padding: 0;
      zoom: 1;
      height: calc(100% - 52px);

      .enlis {
        height: calc(100% - 46px);
        overflow-y: auto;
        font-size: 12px;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        zoom: 1;
        scroll-behavior: smooth; //平滑滚动
        //单个设置
        .mod:last-child {
          border-bottom: 0;
        }
        .mod-box {
          position: relative;
          min-height: 80px;
          width: 100%;
          .mod {
            padding: 14px 2% 0;
            padding-left: 70px;
            line-height: 24px;
            color: #606060;
            cursor: pointer;
            zoom: 1;
            background: rgba(232, 236, 242, 0.25);
            border-radius: 4px;
            // min-height: 72px;
            min-height: 80px;
            // border-bottom: 1px solid #f3f3f3;
            margin-bottom: 8px;
            .col {
              min-height: 24px;
              p {
                display: inline-block;
                margin: 0;
              }
            }
            // 图标
            .col-icon {
              min-height: 24px;
              position: absolute;
              left: 20px;
              top: 15px;
              width: 40px;

              .iconfont {
                background-color: #3e90fe;
                width: 40px;
                height: 40px;
                color: #fff;
                border-radius: 50%;
                line-height: 40px;
                text-align: center;
                display: block;
                margin: 0;
                font-size: 22px;
                font-style: normal;
              }
            }
            // 左侧
            .pull-left {
              float: left !important;
              text-align: left;
              width: 70%;
              .col-num {
                display: inline-block;
                vertical-align: top;
              }
              .col-tit {
              }
            }
            // 右侧
            .pull-right {
              width: 20%;
              float: right !important;
              text-align: right;
              .col-time{
                color: #91A1B7;
              }
              .span-btn {
                // display: none;
                width: 25px;
                visibility: hidden;
              }
            }
          }
          .mod:after {
            content: "";
            display: block;
            clear: both;
          }
          .mod:hover {
            background: rgba(162, 205, 247, 0.25);
            cursor: pointer;
            .span-btn {
              display: inline-block;
              visibility: initial;
            }
          }
          // 更多操作 阴影
          .operate-lis {
            position: absolute;
            width: auto;
            height: 70px;
            top: 75px;
            right: 15px;
            z-index: 99;
            border-radius: 5px;
            .cent {
              position: relative;
              width: auto;
              height: 100%;
              border-radius: 5px;
              .o-mask {
                position: absolute;
                top: -10px;
                right: 20px;
                border-top: 5px transparent dashed;
                border-left: 5px transparent dashed;
                border-right: 5px transparent dashed;
                border-bottom: 5px rgba(25, 47, 69, 0.5) solid;
              }
              .o-lis {
                background: rgba(25, 47, 69, 0.5);
                display: flex;
                position: relative;
                z-index: 120;
                width: auto;
                height: 100%;
                margin: 0 auto;
                align-items: center;
                justify-content: flex-end;
                color: #ffffff;
                font-size: 12px;
                border-radius: 5px;
                cursor: pointer;
                /deep/.en-image-setting {
                  .text {
                    font-size: 12px;
                    color: #ffffff !important;
                  }
                }
                .lis-btn {
                  span {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.myRead__more {
  padding: 0;
  height: 70px;
  z-index: 120;
  width: auto !important;
  margin: 0 auto;
  border: 0;
  border-radius: 5px;
  // .popper__arrow {
  //   border-bottom-color: rgba(25, 47, 69, 0.5);
  //   border-top-color: rgba(25, 47, 69, 0.5);
  // }
  .popper__arrow::after {
    border-bottom-color: rgba(25, 47, 69, 0.5) !important;
    border-top-color: rgba(25, 47, 69, 0.5) !important;
  }
  .o-lis {
    height: 70px;
    width: 100%;
    background: rgba(25, 47, 69, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    .lis-btn {
      background: #fff;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
    .o-lis-tit {
      cursor: pointer;
    }
  }
}
</style>
